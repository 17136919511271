<mat-sidenav-container>
  <mat-sidenav #clientSideNav [fixedInViewport]="false" position="end">
    <app-new-contact-sidenav
      (close)="clientCloseClicked($event)"
      [contact]="sideNavContact"
      [reservationId]="this.form.get('reservation')?.get('id')?.value"
      [wineryId]="companyId"
      [isTourOperator]="isTourOperator && sideNavContact?.contactType != 'SECONDARY'"
    ></app-new-contact-sidenav>
  </mat-sidenav>

  <mat-sidenav-content
    [ngStyle]="{ height: 'auto' }"
    style="overflow-y: auto !important"
    [formGroup]="form"
  >
    <mat-toolbar [ngStyle]="{ background: '#009ADE' }">
      <div class="reservation-title" *ngIf="reservation">
        <span class="reservation-title-ref">Ref.</span>
        <span class="reservation-title-id">{{ reservation?.id }}</span>
        <span class="seatsLabel" *ngIf="getCountForExperience()"
          >{{ "Posti Disponibili" | translate }}:
          {{ getCountForExperience()!.availableSeats }} (
          {{ getCountForExperience()!.occupiedCount }}/{{
            getCountForExperience()!.maxParties
          }}
          )</span
        >
      </div>

      <div *ngIf="!reservation" class="reservation-title">
        {{ "New reservation" | translate }}
      </div>

      <div *ngIf="isGift || giftAssociatedBeforeRevocation" class="gift">
        gift <i class="icon-gift-box-open"></i>
      </div>
      <div
        *ngIf="
          (isGift && (!reservation || !byGift)) ||
          giftAssociatedBeforeRevocation
        "
        class="gift-linked"
      >
        <span (click)="openGiftDialog()" class="link-gift">{{
          "Open gift details" | translate
        }}</span>
      </div>
      <div class="actions">
        <button
          (click)="returnToGift()"
          *ngIf="reservation && byGift"
          mat-icon-button
        >
          <i class="icon-back"></i>
        </button>
        <button
          [matMenuTriggerFor]="moreOptions"
          *ngIf="reservation"
          mat-icon-button
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button (click)="cancelClicked()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>

    <mat-menu #moreOptions="matMenu" xPosition="before">
      <button
        (click)="cloneReservationClicked()"
        mat-menu-item
        *ngIf="!byGift"
      >
        <mat-icon>content_copy</mat-icon>
        {{ "Duplicate" | translate }}
      </button>
      <!-- <button
        (click)="syncReservationClicked($event)"
        mat-menu-item
        *ngIf="this.reservation?.state !== 'waiting'"
      >
        <mat-icon [ngClass]="{ spinning: isSyncingInBackground }">sync</mat-icon>
        {{ "Sync external calendar" | translate }}
      </button> -->
      <web-external-sync-button
        *ngIf="this.reservation?.state !== 'waiting'"
        [label]="'Sync external calendar' | translate"
        [actionObservable]="externalCalendarSync$"
      ></web-external-sync-button>
      <button
          (click)="generatePaymentLinkClicked($event)"
          *ngIf="!paymentLinkData"
          mat-menu-item
          [disabled]="isCreatingPaymentLink || isPaid() || !stripeConnected || (reservation?.dueTotalCents || 0) <= 0"
      >
        <mat-icon>link</mat-icon>
        {{ (isCreatingPaymentLink ? "Creating" : "Generate Payment Link") | translate }}
      </button>
      <button
          (click)="openPaymentLinkDialog()"
          *ngIf="!!paymentLinkData"
          mat-menu-item
      >
        <mat-icon>link</mat-icon>
        {{ "Payment Link" | translate }}
      </button>
      <button
        (click)="openAvailabilityAutomationDialog()"
        *ngIf="reservation && reservation.state == 'confirmed'"
        mat-menu-item
      >
        <mat-icon *ngIf="isAvailabilityBlock">lock_open</mat-icon>
        <mat-icon *ngIf="!isAvailabilityBlock">lock</mat-icon>
        {{ "Availability Automation" | translate }}
      </button>

      <web-external-sync-button
              *ngIf="(reservation && reservation.state == 'confirmed') && regCashIntegrated"
        [label]="'Sync with Cash register' | translate"
        [matIconName]="'currency_exchange'"
        [successMessage]="'Cash register synced successfully' | translate"
        [errorMessage]="'Error syncing cash register, please try again' | translate"
        [actionObservable]="cashRegisterSync$"
        [statusObservable]="cashRegisterStatus$"
      ></web-external-sync-button>
    </mat-menu>

    <div class="content-wrapper">
      <div class="column">
        <div class="content-row" formGroupName="reservation">
          <div class="column columnPadding">
            <div class="reservation-experience-mandatory">
              <div class="content-row">
                <!-- RESERVATION-->
                <div class="field-wrapper reservation-experience column">
                  <!-- EXPERIENCE -->
                  <div *ngIf="!canEditCoreInfo">
                    <label for="title">{{ "Experience" | translate }}</label>
                    <input
                      [value]="experience ? getExperienceName(experience) : ''"
                      id="title"
                      class="big-select"
                      matInput
                      readonly
                      type="text"
                    />
                  </div>

                  <div *ngIf="canEditCoreInfo">
                    <label for="title">{{ "Experience" | translate }}*</label>
                    <input
                      type="text"
                      matInput
                      class="big-select"
                      [matAutocomplete]="auto"
                      [disabled]="reservationToClone"
                      (input)="filterExperiences($event)"
                      [value]="getExperienceName(experience)"
                      (input)="experience = undefined"
                    />
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getExperienceName" (optionSelected)="experience = $event.option.value">
                      <mat-option *ngFor="let experience of filteredExperiences" [value]="experience">
                        {{ getExperienceName(experience) }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
              </div>
              <div class="content-row reservation-information">
                <!-- DATES and time-->
                <ng-container *ngIf="experienceType != 'event'">
                  <div
                    (click)="
                      experience &&
                       canEditField('date') &&
                        (canEdit || confirmed || waiting) &&
                        datepicker.open()
                    "
                    class="field-wrapper column"
                  >
                    <label>{{ "Date" | translate }}*</label>
                    <input
                      [matDatepicker]="datepicker"
                      (dateChange)="reservationDateChanged($event)"
                      formControlName="date"
                      class="smallInput"
                      [readonly]="reservation && !canEditField('date')"
                      id="date1"
                      matInput
                      type="text"
                      autocomplete="off"
                    />
                  </div>

                  <div class="field-wrapper column">
                    <label for="time1">{{ "Time" | translate }}*</label>
                    <input
                      [matAutocomplete]="timeAutoComplete"
                      [readonly]="reservation && !canEditField('time')"
                      formControlName="time"
                      class="smallInput"
                      id="time1"
                      matInput
                      type="time"
                    />
                    <mat-autocomplete #timeAutoComplete="matAutocomplete">
                      <mat-option
                        *ngFor="let time of availableTimes"
                        [value]="time"
                        class="smallInput"
                      >
                        {{ time }}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </ng-container>

                <ng-container *ngIf="experienceType == 'event'">
                  <div class="field-wrapper column">
                    <label for="date2">{{ "Date" | translate }}*</label>
                    <mat-select
                      *ngIf="eventDates?.length"
                      formControlName="event_date"
                      [disabled]="!canEditField('date')"
                      id="event_date"
                    >
                      <mat-option
                        *ngFor="let eventDate of eventDates"
                        [value]="eventDate"
                      >
                        {{ displayDate(eventDate) }}
                      </mat-option>
                    </mat-select>
                    <input
                      *ngIf="!eventDates?.length"
                      [readonly]="true"
                      [value]="'None' | translate"
                      matInput
                      type="text"
                    />
                  </div>
                </ng-container>

                <!-- LANGUAGES -->
                <div class="field-wrapper column">
                  <label for="lang">{{ "Language" | translate }}*</label>
                  <input
                    *ngIf="!canEditCoreInfo"
                    [value]="
                      form.get('reservation')?.get('lang')?.value
                        ? ('lang_' + form.get('reservation')?.get('lang')?.value
                          | translate)
                        : ''
                    "
                    id="lang"
                    matInput
                    class="mediumInput"
                    readonly
                    type="text"
                  />
                  <mat-select
                    *ngIf="canEditCoreInfo"
                    class="mediumInput"
                    [disabled]="!experienceLanguages?.length || reservationToClone?.languageIso"
                    formControlName="lang"
                    [value]="reservationToClone?.languageIso ? 'lang_' + reservationToClone?.languageIso : null"
                  >
                    <mat-option
                      *ngFor="let lang of experienceLanguages"
                      [value]="lang"
                    >
                      {{ "lang_" + lang | translate }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="field-wrapper column">
                    <label for="interval"> {{ ("Duration" | translate) + '*' }}</label>
                  <input
                    [value]="getDuration()"
                    class="smallInput"
                    id="interval"
                    matInput
                    readonly
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="row columnFixedForClient columnWithLBorder columnPadding"
          >
            <div class="row statusRow" style="width: 50%;">
              <!-- STATUS -->
              <div
                class="field-wrapper"
                *ngIf="reservation && reservation.state"
              >
                <label class="labelForSection status-field-label" for="status"
                  >{{ "Status" | translate }}:</label
                >
                <div class="column" style="gap: 10px;">
                  <div class="imageForStatusAndText">
                    <img
                      *ngIf="reservation?.state === 'completed'"
                      alt="completed"
                      class="statusImagesIconReservation"
                      src="assets/icons/status/completed.png"
                    />
                    <img
                      *ngIf="reservation?.state === 'confirmed'"
                      alt="confirmed"
                      class="statusImagesIconReservation"
                      src="assets/icons/status/confirmed.png"
                    />
                    <img
                      *ngIf="reservation?.state === 'draft'"
                      alt="draft"
                      class="statusImagesIconReservation"
                      src="assets/icons/status/draft.png"
                    />
                    <img
                      *ngIf="reservation?.state === 'waiting'"
                      alt="waiting"
                      class="statusImagesIconReservation"
                      src="assets/icons/status/waiting.png"
                    />
                    <img
                      *ngIf="reservation?.state === 'revoked'"
                      alt="revoked"
                      class="statusImagesIconReservation"
                      src="assets/icons/status/revoked.png"
                    />
                    <img
                      *ngIf="reservation?.state === 'rejected'"
                      alt="rejected"
                      class="statusImagesIconReservation"
                      src="assets/icons/status/rejected.png"
                    />
                    <img
                      *ngIf="reservation?.state === 'canceled'"
                      alt="rejected"
                      class="statusImagesIconReservation"
                      src="assets/icons/status/rejected.png"
                    />
                    <label class="singleDetailsStatus color-base-6">{{
                      capitalize(reservation?.state!) | translate
                    }}</label>
                  </div>
                  <button
                          (click)="this.reservation.state === 'waiting' ? confirmReservationClicked() : saveClicked()"
                    class="confirmDeclineButton confirm dws-elevation-z2"
                          *ngIf="reservation && (this.reservation.state === 'waiting' || this.reservation.state === 'draft')"
                          [disabled]="isConfirmingOrRejecting || !canEditField('change_status')"
                    mat-icon-button
                  >
                    <mat-icon>check</mat-icon>
                    {{ "Confirm!" | translate }}
                  </button>
                  <button
                    (click)="rejectReservationClicked()"
                    class="confirmDeclineButton decline dws-elevation-z2"
                    *ngIf="reservation && this.reservation.state === 'waiting'"
                    [disabled]="isConfirmingOrRejecting || !canEditField('change_status')"
                    mat-icon-button
                  >
                    <mat-icon>close</mat-icon>
                    {{ "Reject!" | translate }}
                  </button>
                  <button
                    (click)="revokeReservationClicked()"
                    class="confirmDeclineButton decline dws-elevation-z2"
                    [disabled]="isConfirmingOrRejecting || !canEditField('change_status')"
                    *ngIf="
                      reservation &&
                      (this.reservation.state === 'confirmed' ||
                        this.reservation.state === 'completed')
                    "
                    mat-icon-button
                  >
                    <mat-icon>delete</mat-icon>
                    {{ "Revoke!" | translate }}
                  </button>
                  <button
                    (click)="cancelReservationClicked()"
                    *ngIf="reservation && this.reservation.state === 'draft'"
                    [disabled]="isConfirmingOrRejecting || !canEditField('change_status')"
                    class="confirmDeclineButton decline dws-elevation-z2"
                    mat-icon-button
                  >
                    <mat-icon>delete</mat-icon>
                    {{ "Cancel!" | translate }}
                  </button>
                </div>
              </div>
            </div>
              <div class="column checkBoxLine columnWithLBorder"
                   style="width: 50%; justify-content: space-evenly; margin: 0; padding-right: 0;">
                <div *ngIf="reservation?.state !== 'waiting' && (form.get('reservation')!.get('paid')!.value || !paymentLinkData)"
                     class="field-wrapper" style="flex-direction: row;">
                    <mat-icon>payments</mat-icon>
                    <mat-checkbox
                            (change)="changePaid($event)"
                            class="toolbarCheckDisabled inverted"
                            formControlName="paid"
                            [disabled]="!!paymentLinkData || !canEditField('paid_flag')"
                    >{{ "Paid Check" | translate }}
                    </mat-checkbox
                    >
                </div>
                <div
                        *ngIf="reservation?.state !== 'waiting' && (!form.get('reservation')!.get('paid')!.value && paymentLinkData)"
                        class="field-wrapper"
                        style="flex-direction: row; font-size: smaller;"
                >
                    {{ "Paid Check" | translate }}
                    &nbsp;
                    <mat-icon [matTooltip]="'Waiting for the payment to be completed' | translate">access_time</mat-icon>
                </div>
                  <div *ngIf="reservation?.state === 'waiting'" class="field-wrapper" style="flex-direction: row;">
                  <mat-icon>payments</mat-icon>
                    <mat-checkbox
                            [checked]="form.get('reservation')!.get('paid')?.value! || false"
                            class="toolbarCheckDisabled inverted"
                            style="flex-direction: row;"
                            disabled
                    >{{ "Authorized" | translate }}
                    </mat-checkbox>
              </div>
              <div class="field-wrapper" style="flex-direction: row;">
                  <mat-icon>receipt_long</mat-icon>
                  <mat-checkbox
                          class="toolbarCheckDisabled inverted"
                          [disabled]="!canEditField('fatturato')"
                          formControlName="isSales"
                  >{{ "Fatturato / Scontrino" | translate }}
                  </mat-checkbox>
                  <mat-icon *ngIf="invoiceKeys.length > 0 && !form.get('reservation')!.get('isSales')!.value"
                            [matTooltip]="'Client has requested an invoice' | translate"
                            class="invoice-requested-icon"
                            style="position: absolute; right: 15px;">
                      error
                  </mat-icon>
              </div>
              <div class="field-wrapper" style="flex-direction: row;">
                  <mat-icon>location_on</mat-icon>
                <mat-checkbox
                  class="toolbarCheckDisabled inverted"
                  formControlName="checkedIn"
                  [disabled]="!canEditField('check_in')"
                  >{{ "Check In" | translate }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="content-row rowWithUpperBorder" formGroupName="reservation">
          <div class="column columnPadding minWidth">
            <div class="field-wrapper">
              <label for="labels" class="labelForSection">
                  {{ ("Partecipants" | translate) + '*' }}
              </label>
            </div>

            <div
              *ngFor="let priceLabel of formArrayPriceLabels; let i = index"
              [formGroup]="priceLabel"
              class="field-wrapper"
            >
              <label for="priceLabel">
                {{ getLabelOrExtra(i, "LABEL") | truncate : [16, "..."] }}
                <span class="labelPrice"
                  >({{
                    priceLabel.get("price_cents")?.value / 100
                      | currency : "EUR" : "symbol" : "1.2-2"
                  }})</span
                >
              </label>
              <input
                [readonly]="!canEditPartecipants() || !!paymentLinkData"
                formControlName="quantity"
                id="priceLabel"
                matInput
                min="0"
                type="number"
              />
            </div>
            <div
              class="field-wrapper"
              *ngIf="formArrayPriceExtras && formArrayPriceExtras.length > 0"
            >
              <label for="extras" class="labelForSection">
                <!-- <img alt="conversion" src="assets/icons/icon-price-e.png" /> -->
                {{ "Extra" | translate }}:</label
              >
            </div>

            <div
              *ngFor="let priceExtra of formArrayPriceExtras; let i = index"
              [formGroup]="priceExtra"
              class="field-wrapper"
            >
              <label for="priceExtra">
                {{ getLabelOrExtra(i, "EXTRA") | truncate : [16, "..."] }}
                <span class="labelPrice"
                  >({{
                    priceExtra.get("price_cents")?.value / 100
                      | currency : "EUR" : "symbol" : "1.2-2"
                  }})</span
                >
              </label>
              <input
                [readonly]="!canEditPartecipants() || !!paymentLinkData"
                formControlName="quantity"
                id="priceExtra"
                matInput
                min="0"
                type="number"
              />
            </div>
          </div>

          <div class="column columnWithLBorder columnPadding">
            <div class="field-wrapper">
              <label for="payment_method">
                {{ "Terms of payment" | translate }}</label
              >
              <mat-select
                formControlName="payment_method"
                id="payment_method"
                placeholder="-"
                (selectionChange)="selectPayment2MethodIfThereIsOnlyOne()"
                [disabled]="!!paymentLinkData || !canEditField('payment_method')"
              >
                <mat-option [value]="null"></mat-option>
                <mat-option *ngFor="let pm of paymentMethods" [value]="pm"
                  >{{ pm.nameTranslations[lang] }}
                </mat-option>
              </mat-select>
            </div>

            <div
              *ngIf="form.get('reservation')!.get('payment_method')!.value"
              class="field-wrapper"
            >
              <label for="payment2_method">
                {{ "Method of payment" | translate }}
                <mat-icon style="cursor: pointer;"
                  (click)="openPaymentPage()"
                  *ngIf="paidOnline || (!!paymentLinkData && isPaid())"
                  class="paymentIcon"
                  >launch
                </mat-icon>
              </label>
              <mat-select
                formControlName="payment2_method"
                id="payment2_method"
                [disabled]="!!paymentLinkData || !canEditField('payment_method')"
              >
                <mat-option
                  *ngFor="
                    let pm of form.get('reservation')!.get('payment_method')!
                      .value.children
                  "
                  [value]="pm"
                  [disabled]="!!paymentLinkData"
                >
                  {{ pm.nameTranslations[lang] }}
                </mat-option>
              </mat-select>
            </div>

            <div class="field-wrapper field-total">
              <label for="total_calculated">{{ "Subtotal" | translate }}</label>
              <input
                [suffix]="' ' + currencySymbol"
                formControlName="total_calculated"
                id="total_calculated"
                mask="separator.2"
                readonly
                matInput
                type="text"
              />
            </div>

            <div class="field-wrapper">
              <label for="discount">
                <span class="switch-by-meri">
                  <input
                    #showCoupon
                    [checked]="!isGift"
                    [ngClass]="{ 'gift-associated': isGift }"
                    type="checkbox"
                  />
                  <label for="switcher"></label>
                </span>
              </label>
              <ng-container *ngIf="showCoupon.checked">
                <input
                  *ngIf="reservation?.couponCode || form.get('reservation')?.get('paidAmout')?.value > 0"
                  [suffix]="' ' + currencySymbol + (coupon ? ' - ' + coupon : '')"
                  formControlName="discount"
                  id="discount"
                  mask="separator.2"
                  matInput
                  readonly="true"
                  type="text"
                />
                <mat-select
                  *ngIf="!reservation?.couponCode && form.get('reservation')?.get('paidAmout')?.value <= 0"
                  formControlName="coupon_code"
                >
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let coupon of availableCoupons" [value]="coupon.code">
                    {{ coupon.code }} - {{
                      coupon.discount_type == 'percent'
                        ? coupon.discount_percent + '%'
                        : (coupon.discount_cents / 100 | currency : "EUR" : "symbol" : "1.2-2")
                    }}
                  </mat-option>
                </mat-select>
              </ng-container>
              <input
                *ngIf="!showCoupon.checked"
                [suffix]="' ' + currencySymbol"
                formControlName="giftAmount"
                mask="separator.2"
                matInput
                readonly="true"
                type="text"
              />
            </div>

            <div class="field-wrapper">
              <label for="additional_discount">{{
                "Additional Discount" | translate
              }}</label>
              <input
                [suffix]="' ' + currencySymbol"
                formControlName="additional_discount"
                id="additional_discount"
                mask="separator.2"
                matInput
                type="text"
                (keyup)="additionalDiscountChanged()"
                [readonly]="!!paymentLinkData || !canEditField('additional_discount')"
              />
            </div>

            <div class="field-wrapper field-total">
              <label class="totalTypeLabel" for="total"
                >{{ "Total" | translate
                }}<mat-icon
                  *ngIf="isTotalWarning()"
                  class="warning"
                  [matTooltip]="
                    'The total is not equal to the value of the Subtotal minus the Discounts.'
                      | translate
                  "
                  >warning</mat-icon
                ></label
              >
              <input
                [allowNegativeNumbers]="true"
                [suffix]="' ' + currencySymbol"
                formControlName="total"
                id="total"
                mask="separator.2"
                matInput
                type="text"
                [readonly]="!!paymentLinkData || !canEditField('total')"
              />
            </div>
            <div class="field-wrapper">
              <label class="totalTypeLabel" for="paidAmout">
                {{ "Paied" | translate }}</label
              >
              <input
                [allowNegativeNumbers]="false"
                [suffix]="' ' + currencySymbol"
                formControlName="paidAmout"
                [class.isPaidPositive]="
                  form.get('reservation')?.get('paidAmout')?.value > 0
                "
                id="paidAmout"
                mask="separator.2"
                matInput
                type="text"
                (keyup)="updateTotal('paidAmout', getInputValue($event))"
                [readonly]="!!paymentLinkData || !canEditField('paidAmout')"
              />
            </div>
            <div class="field-wrapper">
              <label class="totalTypeLabel" for="due">{{
                form.get("reservation")?.get("due")?.value >= 0
                  ? ("Due" | translate)
                  : ("Refound" | translate)
              }}</label>
              <input
                [allowNegativeNumbers]="false"
                [suffix]="' ' + currencySymbol"
                [class.isDuePositive]="
                  form.get('reservation')?.get('due')?.value > 0
                "
                formControlName="due"
                id="due"
                mask="separator.2"
                matInput
                type="text"
                (keyup)="updateTotal('due', getInputValue($event))"
                [readonly]="!!paymentLinkData || !canEditField('due')"
              />
            </div>
          </div>

          <div class="column columnWithLBorder columnPadding">
            <div class="reservation-information">
              <div class="reservation-additional-information">
                <div class="field-wrapper">
                  <label for="origin"> {{ "Origin" | translate }}</label>

                  <ng-container
                    *ngIf="reservation && reservation.type != 'manual'"
                  >
                    <input
                      id="origin"
                      matInput
                      readonly
                      type="text"
                      value="{{ getOrigin(reservation.origin) }}"
                    />
                  </ng-container>

                  <ng-container
                    *ngIf="!reservation || reservation.type == 'manual'"
                  >
                    <input
                      *ngIf="!canEdit"
                      formControlName="manual_origin"
                      matInput
                      readonly
                      type="text"
                    />
                    <mat-select
                      *ngIf="canEdit"
                      [compareWith]="compareManualOrigin"
                      [disabled]="!canEditField('origin')"
                      formControlName="manual_origin"
                    >
                      <mat-option value="0"></mat-option>
                      <mat-option
                        *ngFor="let origin of manualOrigins"
                        [value]="origin"
                      >
                        {{ origin | translatable : "name" : "pascal" }}
                      </mat-option>
                    </mat-select>
                  </ng-container>
                </div>

                <div *ngIf="false" class="field-wrapper">
                  <label for="visit_reason">
                    {{ "Visit reason" | translate }}</label
                  >
                  <input
                    *ngIf="!canEdit"
                    formControlName="visit_reason_name"
                    id="visit_reason"
                    matInput
                    readonly
                    type="text"
                  />
                  <mat-select *ngIf="canEdit" [disabled]="!canEditField('visit_reason')"
                              formControlName="visit_reason">
                    <mat-option value=""></mat-option>
                    <mat-option
                      *ngFor="let visitReason of availableVisitReasons"
                      [value]="visitReason.id"
                    >
                      {{ visitReason.nameTranslations[lang] }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="field-wrapper">
                  <label for="room">{{ "Room" | translate }}</label>
                  <mat-select
                    [compareWith]="compareRoom"
                    [disabled]="!canEditField('room')"
                    formControlName="room"
                    id="room"
                  >
                    <mat-option value="0"></mat-option>
                    <mat-option *ngFor="let room of rooms" [value]="room">
                      {{ room.name }}
                    </mat-option>
                  </mat-select>
                </div>

                <div class="field-wrapper">
                  <label for="employee"> {{ "Employee" | translate }}</label>
                  <mat-select
                    [compareWith]="compareEmployee"
                    [disabled]="!canEditField('employee')"
                    formControlName="employee"
                    id="employee"
                  >
                    <mat-option value="0"></mat-option>
                    <mat-option
                      *ngFor="let employee of team"
                      [value]="employee"
                    >
                      {{ employee.firstName }} {{ employee.lastName }}
                    </mat-option>
                  </mat-select>
                </div>
                <div class="field-wrapper notes-field">
                  <label class="notes" for="message">
                    {{ "Message" | translate }}</label
                  >
                  <textarea
                    class="message"
                    formControlName="message"
                    id="message"
                    readonly
                    style="pointer-events: all"
                  ></textarea>
                  <!-- formControlName="message"  -->
                </div>
                <div class="field-wrapper notes-field">
                  <label class="notes" for="notes">
                    {{ "Notes" | translate }}</label
                  >
                  <textarea
                    class="textarea-wrapper"
                    formControlName="notes"
                    [readOnly]="!canEditField('notes')"
                    id="notes"
                    maxlength="500"
                  ></textarea>
                </div>
              </div>

              <br />
            </div>
          </div>

          <div
            class="column columnWithLBorder columnFixedForClient bottom columnPadding"
          >
            <div class="title justify">
              <div>
                <span class="labelForSection" *ngIf="!isTourOperator"
                  >{{ "Client" | translate }}*</span
                >
                <span class="labelForSection" *ngIf="isTourOperator"
                  >{{ "Tour Operator" | translate }}*</span
                >
              </div>
              <div *ngIf="!isTourOperator">
                <mat-slide-toggle
                  [disabled]="!canEdit"
                  formControlName="notify_contact"
                ></mat-slide-toggle>
                <span class="email-label">
                  {{ "Transactional emails" | translate }}
                </span>
              </div>
            </div>
            <div
              class="client-wrapper dws-elevation-z1"
              >
              <div class="client-information">
                <div
                  *ngIf="
                    hasClientAssocieted(masterContactControl?.get('id')?.value)
                  "
                  class="client-data"
                >
                  <div class="rowLT">
                    <div
                      [ngClass]="{ 'pointer': !!masterContactControl?.get('crmContactId')?.value }"
                      (click)="openCrmContactPage(masterContactControl?.get('crmContactId')?.value)"
                    >
                      <strong class="full-name" [ngClass]="{ 'underline': !!masterContactControl?.get('crmContactId')?.value }"
                        >{{ masterContactControl!.get("firstName")?.value }}
                        {{
                          masterContactControl!.get("lastName")?.value
                        }}</strong
                      >
                    </div>
                    <div
                      *ngIf="
                        hasClientAssocieted(
                          masterContactControl?.get('id')?.value
                        )
                      "
                      class="actions"
                    >
                      <button
                        class="addModifySearchButton"
                        (click)="
                          $event.stopPropagation();
                          editClientClicked(
                            masterContactControl?.get('id')?.value
                          )
                        "
                        [disabled]="!!paymentLinkData || !canEditField('contact')"
                      >
                        <i class="icon-edit"></i>
                      </button>
                      <button
                        class="addModifySearchButton"
                        (click)="
                          $event.stopPropagation();
                          removeClientClicked(
                            masterContactControl?.get('id')?.value
                          )
                        "
                        [disabled]="!!paymentLinkData || !canEditField('contact')"
                      >
                        <i class="icon-trash"></i>
                      </button>
                    </div>
                  </div>

                  <div class="rowLT">
                    <strong class="email">{{
                      masterContactControl!.get("email")?.value
                    }}</strong>
                    <div>
                      <strong>
                        {{
                          getOrderCountryByIso(
                            getContactCountryIso(
                              masterContactControl?.get("id")?.value
                            )
                          ) | translatable : "name" : "pascal"
                        }}
                        <img
                          *ngIf="
                            getContactCountryIso(
                              masterContactControl?.get('id')?.value
                            )
                          "
                          class="flag"
                          src="https://www.fotw.info/images/{{
                            getContactCountryIso(
                              masterContactControl?.get('id')?.value
                            )[0]
                          }}/{{
                            getContactCountryIso(
                              masterContactControl?.get('id')?.value
                            )
                          }}.gif"
                        />
                      </strong>
                    </div>
                  </div>
                  <div class="rowLT">
                    <span class="phone">{{
                      masterContactControl!.get("phoneNumber")?.value
                    }}</span>
                  </div>

                    <ng-container *let="{open: false} as invoiceSectionCtrl">
                        <div *ngIf="invoiceKeys.length > 0" class="rowLT">
                            <strong
                                    (click)="invoiceSectionCtrl.open = !invoiceSectionCtrl.open"
                                    class="moreinfo"
                            >
                                {{ "Billing Data" | translate }}
                                <i
                                        [class.arrow-up]="invoiceSectionCtrl.open"
                                        class="icon-arrow-down"
                                ></i>
                            </strong>
                        </div>

                        <div *ngIf="invoiceSectionCtrl.open" class="additionalInfo">
                            <div *ngFor="let invoiceProperty of invoiceKeys" class="row">
                                <strong>{{ ('invoiceFields.' + invoiceProperty) | translate }}</strong>
                                <span>{{ (
                          invoiceData[invoiceProperty]?.nameIt
                            ? (invoiceData[invoiceProperty] | translatable)
                            : invoiceData[invoiceProperty]
                          ) || "-" }}</span>
                            </div>
                        </div>
                    </ng-container>

                  <div
                    *ngIf="masterContactControl?.get('crmContactId')?.value"
                    class="rowLT"
                  >
                    <strong
                      (click)="
                        $event.stopPropagation();
                        contactDetailOpen[
                          masterContactControl?.get('id')?.value
                        ] =
                          !contactDetailOpen[
                            masterContactControl?.get('id')?.value
                          ]
                      "
                      class="moreinfo"
                    >
                      {{ "CRM Customer info" | translate }}
                      <i
                        [class.arrow-up]="
                          contactDetailOpen[
                            masterContactControl?.get('id')?.value
                          ]
                        "
                        class="icon-arrow-down"
                      ></i>
                    </strong>
                    <span
                      (mouseleave)="
                        toggleTooltip($event, false, iconHoverables)
                      "
                      class="hoverableWithPopover"
                      (mouseenter)="toggleTooltip($event, true, iconHoverables)"
                      *ngIf="
                        objectKeys(
                          contactsNotMatchingAlerts[
                            masterContactControl?.get('id')?.value
                          ]
                        )?.length
                      "
                    >
                      <mat-icon class="warning">warning</mat-icon>
                      {{ "Data not matching" | translate }}
                      <web-info-icon-hoverable
                        #iconHoverables
                        [description]="
                          (
                            'reservation-warning-contact-differences'
                            | translate
                          ).replace(
                            '{}',
                            objectKeys(
                              contactsNotMatchingAlerts[
                                masterContactControl?.get('id')?.value
                              ]
                            ).join(', ')
                          )
                        "
                        [overrides]="'left: 50vw !important;'"
                        [showIcon]="false"
                      ></web-info-icon-hoverable>
                    </span>
                  </div>

                  <div
                    *ngIf="
                      masterContactControl?.get('crmContactId')?.value &&
                      contactDetailOpen[masterContactControl?.get('id')?.value]
                    "
                    class="additionalInfo"
                  >
                    <div
                      class="row"
                      *ngFor="
                        let differentInfoName of objectKeys(
                          contactsNotMatchingAlerts[
                            masterContactControl?.get('id')?.value
                          ]
                        )
                      "
                    >
                      <strong style="color: red; font-weight: bold">{{
                        differentInfoName
                      }}</strong>
                      <span>{{
                        contactsNotMatchingAlerts[
                          masterContactControl?.get("id")?.value
                        ][differentInfoName] || "-"
                      }}</span>
                    </div>

                    <div class="row">
                      <strong>{{ "Client value" | translate }}</strong>
                      <span>{{
                        (contactsData[masterContactControl?.get("id")?.value]
                          .contactStatistics?.total || 0) / 100
                          | currency : "EUR" : "symbol"
                      }}</span>
                    </div>

                    <div class="row">
                      <strong>{{ "Last purchases" | translate }}</strong>
                      <span>{{
                        (contactsData[masterContactControl?.get("id")?.value]
                          .contactStatistics?.date | date) || "-"
                      }}</span>
                    </div>

                    <div class="row">
                      <strong>{{ "Total purchases" | translate }}</strong>
                      <span>{{
                        contactsData[masterContactControl?.get("id")?.value]
                          .contactStatistics?.count
                      }}</span>
                    </div>

                    <div class="row">
                      <strong>{{
                        "Marketing subscription" | translate
                      }}</strong>
                      <span
                        *ngIf="
                          (contactsData[masterContactControl?.get('id')?.value]
                            .contactPrivacy?.acceptedPrivacyTerms &&
                            (contactsData[
                              masterContactControl?.get('id')?.value
                            ].contactPrivacy?.acceptedMarketing ||
                              contactsData[
                                masterContactControl?.get('id')?.value
                              ].contactPrivacy?.acceptedProfiling)) ||
                          contactsData[masterContactControl?.get('id')?.value]
                            .contactPrivacy?.manualMarketing
                        "
                      >
                        {{ "Subscribed" | translate }}
                      </span>
                      <span
                        *ngIf="
                          !contactsData[masterContactControl?.get('id')?.value]
                            .contactPrivacy?.acceptedMarketing &&
                          !contactsData[masterContactControl?.get('id')?.value]
                            .contactPrivacy?.acceptedProfiling &&
                          !contactsData[masterContactControl?.get('id')?.value]
                            .contactPrivacy?.manualMarketing
                        "
                      >
                        {{ "Non Subscribered" | translate }}
                      </span>
                      <span
                        *ngIf="
                          contactsData[masterContactControl?.get('id')?.value]
                            .contactPrivacy?.unsubscribed
                        "
                      >
                        {{ "Unsubscribed" | translate }}
                      </span>
                    </div>

                    <div
                      *ngIf="
                        contactsData[masterContactControl?.get('id')?.value]
                          .contactTags?.length
                      "
                      class="row"
                    >
                      <strong>{{ "Tags" | translate }}</strong>
                      <mat-icon
                        (mouseout)="tagsPopoverVisibility = false"
                        (mouseover)="tagsPopoverVisibility = true"
                        style="font-size: large; text-align: right"
                      >
                        remove_red_eye
                      </mat-icon>
                      <div
                        *ngIf="tagsPopoverVisibility"
                        class="popover dws-elevation-z16"
                      >
                        <div
                          *ngFor="
                            let tag of contactsData[
                              masterContactControl?.get('id')?.value
                            ].contactTags
                          "
                          class="tag"
                        >
                          {{ tag.tag.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="
                    !hasClientAssocieted(masterContactControl?.get('id')?.value)
                  "
                  class="client-search-wrapper"
                >
                  <input
                    #trigger="matAutocompleteTrigger"
                    [formControl]="masterSearchControl"
                    [matAutocompleteDisabled]="false"
                    [matAutocomplete]="clientAutoComplete"
                    autocomplete="chrome-off"
                    type="text"
                    [placeholder]="
                      (isTourOperator
                        ? 'Select a tour operator'
                        : 'Select a client') | translate
                    "
                  />

                  <mat-autocomplete
                    #clientAutoComplete="matAutocomplete"
                    (optionSelected)="
                      associateContact(
                        $event.option.value,
                        masterContactControl?.get('id')?.value
                      )
                    "
                    class="autocomplete-new-client"
                    panelWidth="506px"
                  >
                    <mat-option *ngFor="let client of clients" [value]="client">
                      <div class="client-preview">
                        <strong class="full-name">{{ client.name }}</strong>
                        <span class="email">{{ client.email }}</span>
                        <span class="actions"
                          ><i class="icon-plus color-base-5"></i
                        ></span>
                      </div>
                    </mat-option>

                    <mat-option *ngIf="loadingClients" class="loading" disabled>
                      <mat-spinner diameter="35"></mat-spinner>
                    </mat-option>

                    <mat-option
                      *ngIf="
                        clients.length === 0 &&
                        !loadingClients &&
                        !masterSearchControl.pristine
                      "
                      class="no-results-wrapper"
                      disabled
                    >
                      <span>{{ "No user found" | translate }}</span>
                    </mat-option>

                    <mat-option
                            *ngIf="masterSearchControl.pristine"
                      class="loading"
                      disabled
                    >
                    </mat-option>
                  </mat-autocomplete>

                  <div class="actions">
                    <button
                      (click)="addNewClientClicked(masterContactControl?.get('id')?.value)"
                      class="addModifySearchButton"
                      mat-stroked-button
                      type="button"
                    >
                      {{ "New" | translate }}
                    </button>
                  </div>
                </div>
                <div
                  *ngIf="
                    hasClientAssocieted(masterContactControl?.get('id')?.value)
                  "
                  class="feedback-wrapper"
                >
                  <div class="contact-actions-wrapper">
                    <button
                      (click)="$event.stopPropagation(); sendWhatsApp()"
                      *ngIf="masterContactControl?.get('phoneNumber')?.value"
                      class="whButton"
                      mat-flat-button
                    >
                      <img
                        alt="WhatsApp_logo"
                        class="whatsappLogo"
                        src="/assets/images/WhatsApp_logo.png"
                      />
                      <span class="buttonText">{{
                        "WhatsApp" | translate
                      }}</span>
                    </button>
                    &nbsp;
                    <button
                      (click)="$event.stopPropagation(); sendMailTo()"
                      class="emailToConButton"
                      *ngIf="masterContactControl?.get('email')?.value"
                      mat-flat-button
                    >
                      <span class="material-icons">email</span>
                      <span class="buttonText">{{ "Email" | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngFor="let contactCtrl of secondaryContactsControls"
              [formGroup]="contactCtrl"
              class="client-wrapper dws-elevation-z1"
              style="background: white"
            >
              <div class="client-information">
                <div
                  *ngIf="!hasClientAssocieted(contactCtrl!.get('id')!.value)"
                  class="client-search-wrapper"
                >
                  <input
                    #trigger="matAutocompleteTrigger"
                    [formControl]="searchControl"
                    [matAutocompleteDisabled]="false"
                    [matAutocomplete]="clientAutoComplete"
                    autocomplete="chrome-off"
                    type="text"
                  />

                  <mat-autocomplete
                    #clientAutoComplete="matAutocomplete"
                    (optionSelected)="
                      associateContact(
                        $event.option.value,
                        contactCtrl!.get('id')!.value
                      )
                    "
                    class="autocomplete-new-client"
                    panelWidth="506px"
                  >
                    <mat-option *ngFor="let client of clients" [value]="client">
                      <div class="client-preview">
                        <strong class="full-name">{{ client.name }}</strong>
                        <span class="email">{{ client.email }}</span>
                        <span class="actions"
                          ><i class="icon-plus color-base-5"></i
                        ></span>
                      </div>
                    </mat-option>

                    <mat-option *ngIf="loadingClients" class="loading" disabled>
                      <mat-spinner diameter="35"></mat-spinner>
                    </mat-option>

                    <mat-option
                      *ngIf="
                        clients.length === 0 &&
                        !loadingClients &&
                        !searchControl.pristine
                      "
                      class="no-results-wrapper"
                      disabled
                    >
                      <span>{{ "No user found" | translate }}</span>
                    </mat-option>

                    <mat-option
                      *ngIf="searchControl.pristine"
                      class="loading"
                      disabled
                    >
                    </mat-option>
                  </mat-autocomplete>

                  <div class="actions">
                    <button
                      (click)="addNewClientClicked(contactCtrl!.get('id')!.value)"
                      class="addModifySearchButton"
                      mat-stroked-button
                      type="button"
                    >
                      {{ "New" | translate }}
                    </button>
                  </div>
                  <button
                    class="addModifySearchButton"
                    (click)="removeClientClicked(contactCtrl?.get('id')?.value)"
                  >
                    <i class="icon-trash"></i>
                  </button>
                </div>
                <div
                  *ngIf="hasClientAssocieted(contactCtrl!.get('id')!.value)"
                  class="client-data"
                >
                  <div class="rowLT">
                    <div
                      [ngClass]="{ 'pointer': !!contactCtrl.get('crmContactId').value }"
                      (click)="openCrmContactPage(contactCtrl.get('crmContactId').value)"
                    >
                      <strong class="full-name" [ngClass]="{ 'underline': !!contactCtrl?.get('crmContactId')?.value }"
                        >{{ contactCtrl.get("firstName")?.value }}
                        {{ contactCtrl.get("lastName")?.value }}</strong
                      >
                    </div>
                    <div class="actions">
                      <button
                        (click)="
                          $event.stopPropagation();
                          editClientClicked(contactCtrl!.get('id')!.value)
                        "
                        class="addModifySearchButton"
                        mat-stroked-button
                      >
                        <i class="icon-edit"></i>
                      </button>
                      <button
                        (click)="
                          $event.stopPropagation();
                          removeClientClicked(contactCtrl!.get('id')!.value)
                        "
                        class="addModifySearchButton"
                        mat-stroked-button
                      >
                        <i class="icon-trash"></i>
                      </button>
                    </div>
                  </div>

                  <div class="rowLT">
                    <strong class="email">{{
                      contactCtrl.get("email")?.value
                    }}</strong>
                    <div
                      *ngIf="contactCtrl.get('countryIso')?.value as country"
                    >
                      <strong>
                        {{
                          getOrderCountryByIso(country)
                            | translatable : "name" : "pascal"
                        }}
                        <img
                          *ngIf="country"
                          class="flag"
                          src="https://www.fotw.info/images/{{ country[0] }}/{{
                            country
                          }}.gif"
                        />
                      </strong>
                    </div>
                  </div>
                  <div class="rowLT">
                    <span class="phone">{{
                      contactCtrl.get("phoneNumber")?.value
                    }}</span>
                  </div>
                  <div
                    *ngIf="contactCtrl.get('crmContactId').value"
                    class="rowLT"
                  >
                    <strong
                      (click)="
                        $event.stopPropagation();
                        contactDetailOpen[contactCtrl!.get('id')!.value] =
                          !contactDetailOpen[contactCtrl!.get('id')!.value]
                      "
                      class="moreinfo"
                    >
                      {{ "CRM Customer info" | translate }}
                      <i
                        [class.arrow-up]="
                          contactDetailOpen[contactCtrl!.get('id')!.value]
                        "
                        class="icon-arrow-down"
                      ></i>
                    </strong>
                    <span
                      (mouseenter)="toggleTooltip($event, true, iconHoverables)"
                      (mouseleave)="
                        toggleTooltip($event, false, iconHoverables)
                      "
                      *ngIf="
                        objectKeys(
                          contactsNotMatchingAlerts[
                            contactCtrl!.get('id')!.value
                          ]
                        )?.length
                      "
                      class="hoverableWithPopover"
                    >
                      <mat-icon class="warning">warning</mat-icon>
                      {{ "Data not matching" | translate }}
                      <web-info-icon-hoverable
                        #iconHoverables
                        [description]="
                          (
                            'reservation-warning-contact-differences'
                            | translate
                          ).replace(
                            '{}',
                            objectKeys(
                              contactsNotMatchingAlerts[
                                contactCtrl!.get('id')!.value
                              ]
                            ).join(', ')
                          )
                        "
                        [overrides]="'left: 50vw !important;'"
                        [showIcon]="false"
                      ></web-info-icon-hoverable>
                    </span>
                  </div>

                  <div
                    *ngIf="contactDetailOpen[contactCtrl!.get('id')!.value]"
                    class="additionalInfo"
                  >
                    <div
                      class="row"
                      *ngFor="
                        let differentInfoName of objectKeys(
                          contactsNotMatchingAlerts[
                            contactCtrl?.get('id')?.value
                          ]
                        )
                      "
                    >
                      <strong style="color: red; font-weight: bold">{{
                        differentInfoName
                      }}</strong>
                      <span>{{
                        contactsNotMatchingAlerts[
                          contactCtrl?.get("id")?.value
                        ][differentInfoName] || "-"
                      }}</span>
                    </div>
                    <div class="row">
                      <strong>{{ "Client value" | translate }}</strong>
                      <span>{{
                        (contactsData[contactCtrl!.get("id")!.value]
                          .contactStatistics?.total || 0) / 100
                          | currency : "EUR" : "symbol"
                      }}</span>
                    </div>

                    <div class="row">
                      <strong>{{ "Last purchases" | translate }}</strong>
                      <span>{{
                        (contactsData[contactCtrl!.get("id")!.value]
                          .contactStatistics?.date | date) || "-"
                      }}</span>
                    </div>

                    <div class="row">
                      <strong>{{ "Total purchases" | translate }}</strong>
                      <span>{{
                        contactsData[contactCtrl!.get("id")!.value]
                          .contactStatistics?.count
                      }}</span>
                    </div>

                    <div class="row">
                      <strong>{{
                        "Marketing subscription" | translate
                      }}</strong>
                      <span
                        *ngIf="
                          (contactsData[contactCtrl!.get('id')!.value]
                            .contactPrivacy?.acceptedPrivacyTerms &&
                            (contactsData[contactCtrl!.get('id')!.value]
                              .contactPrivacy?.acceptedMarketing ||
                              contactsData[contactCtrl!.get('id')!.value]
                                .contactPrivacy?.acceptedProfiling)) ||
                          contactsData[contactCtrl!.get('id')!.value]
                            .contactPrivacy?.manualMarketing
                        "
                      >
                        {{ "Subscribed" | translate }}
                      </span>
                      <span
                        *ngIf="
                          !contactsData[contactCtrl!.get('id')!.value]
                            .contactPrivacy?.acceptedMarketing &&
                          !contactsData[contactCtrl!.get('id')!.value]
                            .contactPrivacy?.acceptedProfiling &&
                          !contactsData[contactCtrl!.get('id')!.value]
                            .contactPrivacy?.manualMarketing
                        "
                      >
                        {{ "Non Subscribered" | translate }}
                      </span>
                      <span
                        *ngIf="
                          contactsData[contactCtrl!.get('id')!.value]
                            .contactPrivacy?.unsubscribed
                        "
                      >
                        {{ "Unsubscribed" | translate }}
                      </span>
                    </div>
                    <div
                      *ngIf="
                        contactsData[contactCtrl!.get('id')!.value].contactTags
                          ?.length
                      "
                      class="row"
                    >
                      <strong>{{ "Tags" | translate }}</strong>
                      <mat-icon
                        (mouseout)="tagsPopoverVisibility = false"
                        (mouseover)="tagsPopoverVisibility = true"
                        style="font-size: large; text-align: right"
                      >
                        remove_red_eye
                      </mat-icon>
                      <div
                        *ngIf="tagsPopoverVisibility"
                        class="popover dws-elevation-z16"
                      >
                        <div
                          *ngFor="
                            let tag of contactsData[
                              contactCtrl!.get('id')!.value
                            ].contactTags
                          "
                          class="tag"
                        >
                          {{ tag.tag.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  *ngIf="hasClientAssocieted(contactCtrl!.get('id')!.value)"
                  class="feedback-wrapper"
                >
                  <div class="contact-actions-wrapper">
                    <button
                      (click)="sendWhatsApp()"
                      *ngIf="contactCtrl.get('phoneNumber')?.value"
                      class="whButton"
                      mat-flat-button
                    >
                      <img
                        alt="WhatsApp_logo"
                        class="whatsappLogo"
                        src="/assets/images/WhatsApp_logo.png"
                      />
                      <span class="buttonText">{{
                        "WhatsApp" | translate
                      }}</span>
                    </button>
                    &nbsp;
                    <button
                      (click)="sendMailTo()"
                      *ngIf="contactCtrl.get('email')?.value"
                      class="emailToConButton"
                      mat-flat-button
                    >
                      <span class="material-icons">email</span>
                      <span class="buttonText">{{ "Email" | translate }}</span>
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
            <button (click)="addParticipant()" mat-button *ngIf="hasClientAssocieted(masterContactControl?.get('id')?.value)">
              <mat-icon>add</mat-icon>
              {{ "Add participant" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="content-row buttons-row-down rowWithUpperBorder dws-elevation-z8"
    >
      <div class="actions-wrapper buttons-row-lock" style="display: flex;">
      </div>

      <div class="actions-wrapper buttons-row">
        <div class="actions-buttons">
          <button
            (click)="cancelClicked()"
            [disabled]="saveDisabled"
            class="cancelButton"
            mat-stroked-button
          >
            {{ "Cancel" | translate }}
          </button>
          <button
            (click)="saveDraftClicked()"
            *ngIf="isDraftable && !saveDisabled"
            [style]="'font-size: smaller;padding: 0px 15px;'"
            class="dws-elevation-z1"
            mat-stroked-button
          >
            {{ "Save Draft" | translate }}
          </button>
          <button
                  *ngIf="reservation?.state !== 'draft'"
            (click)="saveClicked()"
            [disabled]="saveDisabled"
            [style]="saveDisabled ? 'font-size: small;' : ''"
            class="saveButton dws-elevation-z3"
            mat-flat-button
          >
            {{ (saveDisabled ? "Wait" : "Save") | translate }}
          </button>
        </div>
      </div>
    </div>

    <mat-datepicker
      #monthpicker
      (monthSelected)="monthSelected($event)"
      *ngIf="experienceType != 'event'"
      startView="year"
    ></mat-datepicker>

    <mat-datepicker
      #datepicker
      *ngIf="experienceType != 'event'"
      [calendarHeaderComponent]="
        ('reservation-boundless-datetime' | on) ? undefined : emptyHeader
      "
    ></mat-datepicker>
  </mat-sidenav-content>
</mat-sidenav-container>