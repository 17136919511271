import { Component, Inject, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Gift } from "@interfaces/dws/gift";
import { TranslateDomainPipe } from "@web/app/pipes/translate/translate-domain.pipe";
import { Reservation } from "@interfaces/reservation";
import * as moment from 'moment';
import { tr } from "@util/tr";
import { locale } from "@util/locale";
import { capitalize, set } from "lodash";
import { environment } from '@env/environment';
import { ResendGiftEmail } from '@web/app/components/dialogs/resend-gift-email/resend-gift-email.component';
import { ga } from "@util/ga";
import { ExperiencePriceLabel } from "@interfaces/experience-price-label";
import { NoticeComponent } from '../notice/notice.component';
import { TranslateService } from '@ngx-translate/core';
import { HostExperiencesService } from '@services/host-experiences.service';
import { SendGiftEmail } from '../send-gift-email/send-gift-email.component';
import { observe } from '@web/util/loading/loading';
import { GiftsService } from "@services/dws/gifts.service";
import { GiftLanguageChooserComponent } from '../gift-language-chooser/gift-language-chooser.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { toast } from '@web/util/toast';
import { DomSanitizer } from '@angular/platform-browser';
import { CashRegisterService } from '@services/dws';
import { IntegrationEntityTypes } from '@interfaces/integration-sync-metadata';

@Component({
  selector: 'app-gift-detail',
  templateUrl: './gift-detail.component.html',
  styleUrls: ['./gift-detail.component.scss']
})
export class GiftDetailComponent implements OnInit {
  gift!: Gift;
  reservation?: Reservation;
  form: FormGroup;
  isRedeemed = false;
  priceLabelsFromExperience!: ExperiencePriceLabel[];
  language = "";
  editing = "";
  dateChanged = false;
  dateInfoVisibility = false;
  @ViewChild('sendingDatePicker') sendingDatePicker!: MatDatepicker<Date>;

  regCashIntegrated = false;

  get cashRegisterSync$() {
    return this.cashRegisterService
      .syncGift(this.gift.wineryId!, this.gift!.giftId!.toString());
  }

  get cashRegisterStatus$() {
    return this.cashRegisterService
    .getMetadata(IntegrationEntityTypes.GIFT, this.gift!.id!.toString());
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<GiftDetailComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public translate: TranslateService,
    private experiencesService: HostExperiencesService,
    private giftsService: GiftsService,
    private sanitizer: DomSanitizer,
    private cashRegisterService: CashRegisterService,
  ) {
    this.language = translate.getDefaultLang();
    this.gift = data.gift;
    this.returnToReservation = data.returnToReservation;
    const purchasedDate = this.gift?.createdAt ? moment(this.gift?.createdAt).format('DD/MM/YYYY') : "";
    const sendingDate = this.gift?.beneficiaryNotifyAt ? new Date(this.gift?.beneficiaryNotifyAt) : null;
    const expirationDate = this.gift?.expiresAt ? moment(this.gift?.expiresAt).format('DD/MM/YYYY') : "";
    const redemptionDate = this.gift?.redemptionDate ? moment(this.gift?.redemptionDate).format('DD/MM/YYYY') : "";

    this.form = this.fb.group({
      experienceTitle: [TranslateDomainPipe.transform(this.gift!.extraData.experience.title, '')],
      purchasedDate: [purchasedDate],
      origin: [this.gift?.extraData.origin],
      sendingDate: [sendingDate],
      price01: [this.gift?.adults],
      price02: [this.gift?.children],
      expirationDate: [expirationDate],
      redemptionDate: [redemptionDate],
      status: [tr(capitalize(this.gift?.aasmState))],
      method: [this.gift?.extraData?.order?.paymentMethod],
      paid: [this.gift?.aasmState === 'paid' ? tr("Yes") : tr("No")],
      discountCode: [this.gift?.extraData?.coupon?.code],
      paymentStatus: [this.gift?.extraData?.order.paymentStatus],
      experiencePriceLabels: this.fb.array([]),
      beneficiaryName: [this.gift?.beneficiaryFirstname + ' ' + this.gift?.beneficiaryLastname],
      beneficiaryEmail: [this.gift?.beneficiaryEmail],
    });

    const control = this.formPriceLabelsArray;
    const priceLabels = this.gift?.experiencePriceLabels;
    priceLabels.forEach(pl => {
      control.push(
        this.createFormGroup(this.fb, pl)
      )
    });

    this.validateGiftSendingDate = this.validateGiftSendingDate.bind(this);
    this.giftsService = giftsService;

    this.cashRegisterService
      .isIntegrated()
      .subscribe((x) => {
        this.regCashIntegrated = x;
      });
  }

  @Input() returnToReservation?: boolean;

  ngOnInit() {
    ga.track(`Gift ${this.gift?.giftId}`);
    if (this.gift?.aasmState != 'redeemed') {
      this.isRedeemed = true;
    }
  }

  isExpired() {
    return new Date(this.gift.expiresAt) < new Date();
  }

  get beneficiaryName(): string {
    return (this.gift?.beneficiaryFirstname || '') + ' ' + (this.gift?.beneficiaryLastname || '')
  }

  get buyerName(): string {
    return (this.gift?.firstname || '') + ' ' + (this.gift?.lastname || '')
  }

  get beneficiaryAlreadyNotified(): boolean {
    return moment(this.gift?.beneficiaryNotifyAt) <= moment() ? true : false;
  }

  get isSaveButtonDisabled(): boolean {
    return this.editing != "" || (
      this.form.value.beneficiaryName == this.beneficiaryName &&
      this.form.value.beneficiaryEmail == this.gift?.beneficiaryEmail &&
      moment(this.form.value.sendingDate).format('DD/MM/YYYY') == moment(this.gift?.beneficiaryNotifyAt).format('DD/MM/YYYY')
    );
  }

  redeemGiftLink() {
    if (this.isExpired()) {
      this.translate
        .get('The gift expired on {expirationDate}. It cannot be redeemed.', {
          expirationDate: moment(this.gift?.expiresAt).format('DD/MM/YYYY'),
        })
        .subscribe(async (res: string) => {
          this.dialog.open(NoticeComponent, {
            minWidth: 550,
            maxWidth: 550,
            panelClass: "no-overflow",
            data: {
              text: res,
            },
          });
        });
    } else {
      var experienceId = this.gift?.extraData.experience.id;

      this.experiencesService.get(experienceId)
        .subscribe(experience => {
          if (experience && experience.active && experience.deleted_at === null) {
            var giftId = this.gift.giftId;
            const site = environment.values.divinea;
            const link = `https://${site}/esperienza/${experienceId}?redeem_code=${giftId}`;
            window.open(link);
          } else {
            this.dialog.open(NoticeComponent, {
              minWidth: 550,
              maxWidth: 550,
              panelClass: "no-overflow",
              data: {
                text: tr("The gift-related experience has undergone changes or has been disabled. Unable to redeem the gift."),
              },
            });
          }
        })
    }
  }

  closeClicked() {
    this.dlg.close({ loadReservation: false });
  }

  openReservation() {
    this.dlg.close({ loadReservation: true })
  }

  validateGiftSendingDate(d: Date): boolean {
    if (d == null) return false;
    let date = moment(d);
    return date.isSameOrAfter(moment(), 'day') && date.isSameOrBefore(moment(this.gift.expiresAt), 'day');
  }

  openGiftDetails(gift: Gift) {
    const dialog = this.dialog.open(GiftDetailComponent, {
      width: '50%',
      minWidth: 1000,
      maxWidth: 1000,
      data: {
        gift
      }
    });

    dialog.afterClosed().subscribe(res => {
    });
  }

  price01label() {
    var priceLabel = this.gift.extraData.experience.priceLabel01
    if (priceLabel == null) return tr('Price 1')
    return locale().locale == 'it' ? priceLabel.it : priceLabel.en
  }

  price02label() {
    var priceLabel = this.gift.extraData.experience.priceLabel02
    if (priceLabel == null) return tr('Price 2')
    return locale().locale == 'it' ? priceLabel.it : priceLabel.en
  }

  get formPriceLabelsArray() {
    return (this.form.get('experiencePriceLabels') as FormArray).controls as FormGroup[];
  }

  createFormGroup(fb: FormBuilder, ex?: Partial<ExperiencePriceLabel>) {
    const f = fb.group({
      id: [''],
      quantity: [''],
      title_translations: [''],
      experience_id: ['']
    })
    if (ex) {
      f.patchValue({
        ...ex,
        'quantity': ex.quantity,
        'title_en': ex.title_translations?.en,
        'title_it': ex.title_translations?.it,
        'title_de': ex.title_translations?.de,
        experience_id: ex.experience_id
      });
    }
    return f;
  }

  async resendEmail() {
    const dlg = this.dialog.open(ResendGiftEmail, {
      width: '50%',
      minWidth: '45%',
      maxWidth: '45%',
      data: {
        giftId: this.gift.giftId,
        giftEmail: this.gift.beneficiaryEmail
      }
    })

    await dlg.afterClosed().toPromise();

  }

  async sendGiftEmail() {
    let name = this.form.value.beneficiaryName.split(' ');
    let firstname: string = name.shift();
    let lastname: string = name.shift();
    name.forEach((n: string) => lastname = lastname + ' ' + n);

    const dlg = this.dialog.open(SendGiftEmail, {
      width: '50%',
      minWidth: '45%',
      maxWidth: '45%',
      data: {
        giftId: this.gift.giftId,
        giftEmail: this.form.value.beneficiaryEmail,
      }
    })
  }

  updateGift() {
    let name = this.form.value.beneficiaryName.split(' ');
    let firstname: string = name.shift();
    let lastname: string = name.shift();
    name.forEach((n: string) => lastname = lastname + ' ' + n);
    let day = moment(this.form.value.sendingDate).format('DD/MM/YYYY');
    let oldDay = moment(this.gift.beneficiaryNotifyAt).format('DD/MM/YYYY');

    this.giftsService.update(
      this.gift.giftId,
      this.form.value.beneficiaryEmail,
      day,
      firstname,
      lastname
    ).subscribe(() => {
      toast(
        day == moment().format('DD/MM/YYYY') && oldDay != moment().format('DD/MM/YYYY')
          ? tr("The gift has been updated and the email has been sent successfully")
          : tr("Gift updated successfully")
      );
      setTimeout(() => window.location.reload(), 4000);
    });
  }

  edit(component: string, save?: boolean) {
    if (this.editing == "") {
      this.editing = component;
    } else if (this.editing == component) {
      this.editing = "";
      if (!save) {
        if (component != "beneficiaryName") {
          this.form.controls[component].setValue(this.gift[component as keyof Gift]);
        } else {
          this.form.controls[component].setValue(this.gift.beneficiaryFirstname + ' ' + this.gift.beneficiaryLastname);
        }
      }
    }
    if (this.editing == "sending-date") {
      this.sendingDatePicker.open();
    }
  }

  getGiftVoucher() {
    this.openLanguageDialog();
  }

  openLanguageDialog(): void {
    const dialogRef = this.dialog.open(GiftLanguageChooserComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // User selected a language, use it in your code
        var lang = result;
        if (!!!!lang) {
          var giftId = this.gift.giftId;
          var wineryId = this.gift.wineryId;

          observe(this.giftsService.getPDF(wineryId!, giftId, lang))
            .subscribe((data: Blob) => {
              const url = URL.createObjectURL(data);

              // Create a link and trigger the download
              const link = document.createElement('a');
              link.href = url;
              link.download = 'gift_voucher_' + giftId + '.pdf';
              document.body.appendChild(link);
              link.click();

              // Cleanup: Remove the link and revoke the URL
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            });
        }
      }
    });
  }

  get invoiceKeys() {
    let k = Object.keys(this.gift.extraData?.invoiceData || {});
    // impose manual order
    return [
      "companyName",
      "vat",
      "fiscalCode",
      "sdi",
      "billingCountry",
      "billingAddress",
      "billingCity",
      "billingProvince",
      "billingZipCode",
    ].filter((x) => k.includes(x));
  }

  openBillingData() {
    if (!this.invoiceKeys) return;

    let text = tr("Billing Data") + ":";
    let items = this.invoiceKeys.map((x) => this.sanitizer.bypassSecurityTrustHtml(
      `<div style="display: inline-flex; justify-content: space-between; width: 100%;"><div>${
        tr('invoiceFields.' + x)
      }:</div><div>${
        this.gift.extraData.invoiceData[x]?.nameIt
          ? TranslateDomainPipe.transform( this.gift.extraData.invoiceData[x] )
          : this.gift.extraData.invoiceData[x]
      }</div></div>`
    ));

    this.dialog.open(NoticeComponent, {
      minWidth: 550,
      maxWidth: 550,
      panelClass: "no-overflow",
      data: {
        text,
        items,
      },
    });
  }
}